import styled from 'styled-components';
import colors from '../../../constants/style/colors';

export const Button = styled.button`
  text-transform: uppercase;
  display: inline-block;
  padding: 10px;
  border: 2px solid ${colors.grey};
  cursor: pointer;
  background-color: transparent;
`;

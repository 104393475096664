import React from 'react';
import { Title, TitleProps } from './Title.style';

export interface Props extends TitleProps {
  children: React.ReactNode;
}

export default ({ children, ...rest }: Props) => (
  <Title {...rest}>{children}</Title>
);

import { withPrefix } from 'gatsby';
import styled from 'styled-components';

export interface TitleProps {
  align?: 'left' | 'right' | 'center';
}

export const Title = styled('h2')`
  text-transform: uppercase;
  font-family: AvertaThin;
  font-size: 48px;
  font-weight: bold;
  word-spacing: 5px;
  letter-spacing: 5px;
  overflow-wrap: break-word;
  text-align: ${({ align }: TitleProps) => (align ? align : 'center')};
  margin-top: 60px;
  margin-bottom: 10px;
  line-height: 60px;

  @media (max-width: 1024px) {
    font-size: 26px;
    line-height: 36px;
    margin-top: 36px;
  }

  @media (max-width: 813px) {
    font-size: 18px;
    line-height: 28px;
    margin-top: 28px;
    padding: 0 10%;
  }
`;
